
import * as React from 'react';
import Categories from './Categories';
import Navbar from './Navbar';
export default function Home() {
  return (
    <>
    <Navbar/>
    <Categories/>
    </>
    
  );
}

